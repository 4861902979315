<template>
  <UserSearchResultCard
    to="specialist-profile"
    :data="specialist"
    :skills="specialist.skills"
    price-title="Ставка в час"
    :price="specialist.price"
  >
    <template v-if="specialist.id !== user?.id" #action>
      <SpecialistRequestButton
        @click.stop="onClick"
        v-if="!specialist.contacts"
        :specialist="specialist"
        @update:contacts="specialist.contacts = $event"
        @update:status="specialist.status_owner_spec = $event"
      />
      <ProfileContacts v-else :contacts="specialist.contacts" />
    </template>
  </UserSearchResultCard>
  <FeedbackModal :modal-data="feedbackModalData"/>
</template>

<script setup>
import useForm from "~/composables/useForm";
import {feedback} from "~/api/feedback";

onMounted(() => {
  (function (w, d, s, o) {
    var j = d.createElement(s);
    j.async = true;
    j.src = '//script.marquiz.ru/v2.js';
    j.onload = function () {
      if (document.readyState !== 'loading') Marquiz.init(o);
      else document.addEventListener("DOMContentLoaded", function () {
        Marquiz.init(o);
      });
    };
    d.head.insertBefore(j, d.head.firstElementChild);
  })(window, document, 'script', {
      host: '//quiz.marquiz.ru',
      region: 'eu',
      id: '65ba494ab26e910026a413d9',
      autoOpen: false,
      autoOpenFreq: 'once',
      openOnExit: false,
      disableOnMobile: false
    }
  );
})

const props = defineProps({
  specialist: Object
})

const router = useRouter()
const user = inject('user')

// const callForm = () => {
//   Marquiz.showModal('65ba494ab26e910026a413d9')
// }

const feedbackModalData = ref({
  type: 'specialist',
  specialist_id: props.specialist.id,
  isShow: false,
})

function onClick() {
  return feedbackModalData.value.isShow = true
}
</script>

<style scoped lang="scss">
.business {
  img {
    object-fit: contain;
  }

  section {
    margin-bottom: 130px;
  }

  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    border-radius: 30px;
    background-color: var(--color-bg-secondary);
    margin-bottom: 25px;

    &-title {
      margin-bottom: 24px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        font-size: 54px;
        font-weight: 900;
        line-height: 70px;
        letter-spacing: 1.25px;
        text-align: center;
        text-transform: uppercase
      }

      img {
        display: inline-block;
      }
    }

    &-subtitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    z-index: 9999999999;
  }

  &-modal {
    margin: 0 auto;
    background-color: var(--color-bg);
    padding: 32px;
    text-align: center;
    max-width: 450px;
    border-radius: 16px;

    .icon-close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 24px;
      color: var(--color-elements-tertiary);
      cursor: pointer;
    }

    h3 {
      font-size: 28px;
      font-weight: 900;
      line-height: 38px;
      margin-bottom: 32px;
    }

    .input-container {
      margin-bottom: 16px;
    }

    .input-container:last-child {
      margin-bottom: 32px;
    }

    .form-note {
      text-align: center;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
    }
  }
}
.business-form-right-social-button{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
@media screen and (max-width: 810px) {
  .business-form-right-social-button {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
}
</style>
